import { DeleteOutlined, EditOutlined, FilePdfTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { BPFCostingParentDto, BargeCostingIdReqDto, BargeCostingVouchersParentDto, BargeCostingsService, ContractStatusEnum, PlantCodeReqDto, ReferenceFeatures, bargeCostingDeleteIdReqDto } from '@exportx/shared-models-and-services';
import { getLocalFormat } from '@exportx/ui-utils';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Divider, Input, Popconfirm, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import Invoice from '../../invoice/invoice';
import { SequenceUtils } from '@exportx/ui-utils';


interface IBargeCostingsList {
    setEditDataToForm: (data: any) => void;
    paramsId?: any
}

export const BargeCostingsList = (props: IBargeCostingsList) => {
    const { formatMessage: fm } = useIntl();
    const { authContext } = useAuthState();
    const [bgVouchers, setBgVouchers] = useState<BargeCostingVouchersParentDto>();
    const [bargeCostingData, setBargeCostingData] = useState<BPFCostingParentDto[]>([])
    const service = new BargeCostingsService();
    const [drawerOpen, setDrawerOpen] = useState<boolean>();
    const [costingId, setCostingId] = useState<any>();
    const [dummyRefresh, setDummyRefresh] = useState<number>(1);
    const [searchedText, setSearchedText] = useState("");



    useEffect(() => {
        service.getBargeCostingRejectedParentData(new PlantCodeReqDto(authContext.defaultPlant)).then(res => {
            if (res.status) {
                setBargeCostingData(res.data);
            } else {
                setBargeCostingData([]);
            }
        }).catch(err => {
            console.log(err)
            setBargeCostingData([]);
        })
    }, []);

    useEffect(() => {
        editHandler(props.paramsId)
    }, [props.paramsId])

    const deleteBargeCosting = (costingId) => {
        const req = new bargeCostingDeleteIdReqDto(costingId, authContext.defaultPlant)
        service.deleteBargeCosting(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }


    const editHandler = (costingId: string) => {
        service.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingId, authContext.defaultPlant)).then(res => {
            if (res.status) {
                props.setEditDataToForm(res.data);
            } else {
                //setBargeCostingData([]);
            }
        }).catch(err => {
            console.log(err)
            // setBargeCostingData([]);
        })
    }


    const vouchersData = (id: string) => {
        const req = new BargeCostingIdReqDto(id, authContext.defaultPlant)
        service.getBargeCostingInvoice(req).then(res => {
            if (res.status) {
                setBgVouchers(res.data);
                setDummyRefresh(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setBgVouchers(undefined);
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Action',
            dataIndex: 'action',
            width: '20%',
            align: 'center',
            render: (text, rowData) => {
                return (
                    <span>
                        {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) &&

                            <Tooltip placement="topRight" title="Edit">
                                <EditOutlined type="edit"
                                    onClick={() => editHandler(rowData.costingId)}
                                    style={{ color: '#1890ff', fontSize: '20px' }}
                                /></Tooltip>}

                        <Divider type="vertical" />

                        <Popconfirm
                            onConfirm={e => {
                                //activateOrDeactivate(RowData);
                                deleteBargeCosting(rowData.costingId)
                            }}
                            title={fm({ id: "common.deleteConfirmMsg", defaultMessage: 'Are you sure to Delete Record ?' })}>
                            <Tooltip placement="topRight" title="Delete">
                                <DeleteOutlined type='delete' onClick={() => { }} style={{ color: 'red', fontSize: '20px' }} />
                            </Tooltip>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Tooltip placement="topRight" title="Add Invoice">
                            <PlusSquareTwoTone onClick={() => {
                                setDrawerOpen(true);
                                setCostingId((prev) => { return { ...prev, costingId: rowData.costingId, totalAmount: rowData.totalSumAmount } });
                                setDummyRefresh(prev => prev + 1);
                            }}
                                style={{ color: '#1890ff', fontSize: '20px' }} />
                        </Tooltip>
                        <Divider type='vertical'></Divider>
                        <PDFDownloadLink
                            document={<Invoice key={dummyRefresh} invoice={bgVouchers} demurrageName={''} costingName={ReferenceFeatures.BARGE_CPC_COSTING} />}
                            fileName={`${rowData.costingNo}.pdf`}
                        >
                            <Tooltip placement="topRight" title="Invoice">
                                <FilePdfTwoTone onClick={() => vouchersData(rowData.costingId)} style={{ color: '#1890ff', fontSize: '20px' }}
                                />
                            </Tooltip>

                        </PDFDownloadLink>
                    </span>
                )
            }
        },
        {
            title: 'Costing No',
            dataIndex: 'costingNo',
            sorter: (a, b) => a.costingNo.localeCompare(b.costingNo),
            sortDirections: ['descend', 'ascend'],
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: 'Date',
            dataIndex: 'costingDate',
            sorter: (a, b) => moment(a.costingDate).unix() - moment(b.costingDate).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
            }
        },
        {
            title: 'Business No',
            dataIndex: 'businessNumber',
            render: (value, record) => {
                if (record.bnId) {
                    const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
                    return <a href={link} target='_blank' className="link-primary" >{value}</a>

                }
                return <span>{value}</span>
            }
        },
        {
            title: 'Barges',
            dataIndex: 'bargeNo',
            render: (value, record) => {
                if (record.bargeId) {
                    const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
                    return <a href={link} className="link-primary" target="_blank">
                        {`${SequenceUtils.formatNumberToSpecificLength(record.bargeNo)} (${record.bargeName})`}
                    </a>

                }
                return <span>{`${value} (${record.bargeName})`}</span>
            }
        },

        {


            title: 'Supplier',
            dataIndex: 'bargeSupplier',
            sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
            sortDirections: ['descend', 'ascend'],

        },
        {
            title: 'Payable',
            dataIndex: 'totalAmount',
            align: 'right',
            sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
                return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
            }
        }
    ]
    return (
        <>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />
            <Table
                className='contracts'
                rowKey={record => record.costingId}
                columns={tableColumns}
                dataSource={bargeCostingData}
                scroll={{ x: 500 }}
                //size="large"
                bordered
                style={{ width: '100%' }}
                rowClassName={(record, index) => record.approvalStatus === ContractStatusEnum.CANCELLED ? 'bg-warning' : ''}
            />
            <AddInvoiceDrawer referenceFeatures={ReferenceFeatures.BARGE_CPC_COSTING} key={dummyRefresh} setDummyRefresh={setDummyRefresh} openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingId} />

        </>



    )
}

export default BargeCostingsList;