import { DeleteOutlined, EditOutlined, FilePdfTwoTone, PlusSquareTwoTone, MinusSquareTwoTone } from '@ant-design/icons';
import { BargeCostingVouchersParentDto, ContractStatusEnum, ExpenseEntryIdReq, ExpenseEntryService, NoteTypeReq, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Divider, Input, Popconfirm, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, StatusTag, useAuthState } from '../../../../common';
import Invoice from '../../invoice/invoice';
import { SequenceUtils } from '@exportx/ui-utils';
import AddInvoiceDrawer from '../../add-invoice-drawer';
export interface ExpenseEntryRejectedListProps {
    setEditDataToForm: (values) => void
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>

    updateExpensive: any,
    type: string,
}

export default function ExpenseEntryRejectedList(props: ExpenseEntryRejectedListProps) {
    const { setDummyRefresh, updateExpensive } = props
    const [rejectedEntries, setRejectedEntries] = useState<any[]>([])
    const service = new ExpenseEntryService()
    const { authContext } = useAuthState();
    const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>();
    const [searchedText, setSearchedText] = useState("");
    const [drawerOpen, setDrawerOpen] = useState<boolean>();
    const [costingId, setCostingId] = useState<any>();

    useEffect(() => {
        getRejectedEntries()
    }, [props.type])

    const deleteEntry = (value) => {
        console.log(value)
        const req = new ExpenseEntryIdReq()
        req.expenseEntryId = value

        service.deleteEntry(req).then((res) => {
            if (res.status) {
                setDummyRefresh(prev => prev + 1);
                AlertMessages.getSuccessMessage(res.internalMessage)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)

            }
        }).catch((err) => {
            AlertMessages.getErrorMessage('')
        })
    }


    const getRejectedEntries = () => {
        const req = new NoteTypeReq(props.type === 'Account Receivable' ? 'Other Income' : '', authContext.defaultPlant)
        // const req = new PlantCodeReqDto(authContext.defaultPlant)
        service.getRejectedEntries(req).then((res) => {
            if (res.status) {
                console.log('getRejectedEntries', res)
                setRejectedEntries(res.data)
            } else {
                setRejectedEntries([])
            }
        }).catch(err => {
            console.log('err', err)
            setRejectedEntries([])

        })
    }


    const getExpensiveInvoiceData = (id: string) => {
        const req = new ExpenseEntryIdReq();
        req.expenseEntryId = id;
        req.plantCode = authContext.defaultPlant
        service.getExpensiveInvoiceData(req).then(res => {
            if (res.status) {
                setVouchers(res.data);
            } else {
                console.log(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }

    const columns: any = [
        {
            title: 'Actin',
            dataIndex: 'isInPmtNotice',
            width: '15%',
            align: 'center',
            key: 'expenseEntryId',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (text, rowData) => (
                <span>
                  {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) &&

                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined
                            type="edit"
                            onClick={() => updateExpensive(rowData.expenseEntryId)}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        /></Tooltip>}

                    <Divider type="vertical" />
                    <Popconfirm
                        disabled={text}
                        onConfirm={e => {
                            deleteEntry(rowData.expenseEntryId)
                        }}
                        title={'Are you sure to delete ?'}>
                        <Tooltip placement="topRight" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
                            <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
                        </Tooltip>
                    </Popconfirm>
                    <Divider type="vertical" />
                    <Tooltip placement="topRight" title="Add Invoice">
                        <PlusSquareTwoTone onClick={() => {
                            setDrawerOpen(true);
                            setCostingId((prev) => { return { ...prev, costingId: rowData.expenseEntryId, totalAmount: rowData.totalAmount } });
                            // setDummyRefresh(prev => prev + 1);
                        }}
                            style={{ color: '#1890ff', fontSize: '20px' }} />
                    </Tooltip>
                     {/* <Tooltip placement="topRight" title="Add Invoice">
                        {!invoiceFrom ? <>
                            <PlusSquareTwoTone
                                key={rowData.expenseEntryId}
                                onClick={() => {
                                    setInvoiceForm(true);
                                    setInvoiceCostingData((prev) => { return { ...prev, referenceFeatures: ReferenceFeatures.EXPENSE_ENTRY, costingId: rowData.expenseEntryId, totalAmount: Number(rowData.totalAmount) } })

                                }}
                                style={{ color: '#1890ff', fontSize: '20px' }} />

                        </> : <>


                            <MinusSquareTwoTone key={rowData.expenseEntryId} style={{ color: '#1890ff', fontSize: '20px' }} onClick={() => {
                                setInvoiceForm(false);
                            }} />
                        </>

                        }


                    </Tooltip> */}

                    <Divider type='vertical'></Divider>

                    <PDFDownloadLink
                        document={<Invoice invoice={vouchers} demurrageName={''} costingName={vouchers?.costingName} />}
                        fileName={`${rowData?.expenseEntryNo}.pdf`}
                    >
                        <Tooltip placement="topRight" title="Invoice">
                            <FilePdfTwoTone onClick={() => getExpensiveInvoiceData(rowData.expenseEntryId)} style={{ color: '#1890ff', fontSize: '20px' }}
                            />
                        </Tooltip>
                    </PDFDownloadLink>

                </span>
            )
        },
        {
            title: 'Entry Ref#',
            dataIndex: 'expenseEntryNo',
            sorter: (a, b) => a.expenseEntryNo.localeCompare(b.expenseEntryNo),
            sortDirections: ['descend', 'ascend'],
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: 'Expense Ledger',
            dataIndex: 'act_led_name',
            
        },
        {
            title: 'Expense Type',
            dataIndex: 'expensiveType',
            sorter: (a, b) => a.expensiveType.localeCompare(b.expensiveType),
            sortDirections: ['descend', 'ascend'],
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            align: 'right',
            sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
            sortDirections: ['descend', 'ascend'],
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value) => Number(value).toFixed(2)
        },
        {
            title: 'BusinessNo',
            dataIndex: 'businessNumber',
            sorter: (a, b) => a.businessNumber.localeCompare(b.businessNumber),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
                const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
                return <>
                    <a href={link}>{value}</a>
                </>
            }

        },
        {
            title: 'Barges',
            dataIndex: 'bargeId',
            sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
            sortDirections: ['descend', 'ascend'],
            render: (v, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.bgId}`;
                return <>
                    {v === undefined || v === null ? "(null)" :
                        <a href={link} className="link-primary">
                            {SequenceUtils.formatNumberToSpecificLength(v) + "-" + `(${record.bargeName})`}
                        </a>}
                </>
            }
        },

        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, _) => {
                return <>
                    <StatusTag status={value}></StatusTag>
                </>
            }
        },
    ];


    const entriesDataModification = (data: any) => {
        const entriesData = [];
        data.forEach((parent) => {
            parent.transactionalData.forEach((child, index) => {
                entriesData.push({
                    expenseEntryNo: parent.expenseEntryNo,
                    expensiveType: parent.expensiveType,
                    totalAmount: parent.totalAmount,
                    // rowSpan: index === 0 ? parent.transactionalData.length : 0,
                    businessNumber: child.businessNumber + `(${child.motherVesselName})`,
                    bargeId: child.bargeId,
                    approvalStatus: parent.approvalStatus,
                    bnId: child.bnId,
                    bgId: child.bgId,
                    expenseEntryId: parent.expenseEntryId,
                    bargeName: child.bargeName,
                    isInPmtNotice: parent.isInPmtNotice,
                    act_led_name: parent.act_led_name



                })

            })
        })

        return entriesData
    }


    return (
        <>

            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />    <Table
                columns={columns}
                size='small' bordered
                dataSource={entriesDataModification(rejectedEntries)}
                rowKey={(row) => row.expenseEntryId}
            />

            <AddInvoiceDrawer openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingId} setDummyRefresh={setDummyRefresh} referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY} />
            {/* {invoiceFrom &&

                <AddInvoice key={inVoiceCostingData.expenseEntryId} costingData={inVoiceCostingData} />
            } */}
        </>
    )
}
