import { CopyFilled, EditOutlined, FileExcelFilled, PlusCircleFilled, PlusSquareOutlined, MoreOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { BargeBlCreationId, BargeIdRequestDto, BargeService, ContractIdRequest, ContractStatusEnum, PlantCodeReqDto, PurchaseContractService, PurchaseDropDownDto } from "@exportx/shared-models-and-services";
import { SequenceUtils, getBase64 } from "@exportx/ui-utils";
import { Button, Card, Col, Drawer, Form, Input, Menu, Modal, Row, Dropdown, Select, Table, Tabs, Tooltip } from "antd";
import { Excel } from "antd-table-saveas-excel";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AlertMessages, StatusTag, useAuthState } from "../../../../common";
import BlCreation from "./barge-bl-creation/bl-creation";
import { TablePaginationConfig, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';





export const BargeView = () => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [, setOpen] = useState(false);
  const [loading,] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const { TabPane } = Tabs;
  const [defaultTab,] = useState<string>('0');
  const [counter, setCounter] = useState(0);
  const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
  const [contractData, setContractData] = useState<string>(null)
  const purchase = new PurchaseContractService();
  const service = new BargeService();
  let pathToreDirect = '/barge-detail-view';
  let pathToView = '/po-detail-view';
  const { Option } = Select;
  const [showModal, setShowModal] = useState<boolean>(false)
  const [pcQualitiesSelectedRowData, setPcQualitiesSelectedRowData] = useState<any>()
  const [selectedBargeId, setSelectedBargeId] = useState<any>()
  const [qualityData, setQualityData] = useState([]);
  const [pcTypeData, setPcTypeData] = useState<any>()
  const [indexValue, setIndexValue] = useState<number>()
  const [blCreationFormRef] = Form.useForm();
  const [showDrawer, setDrawer] = useState(false)
  const [activeBlCreation, setActiveBlCreation] = useState<string>("")
  const [activeBlGrid, setActiveBlGrid] = useState<any>([])
  const [dummyRefresh, setDummyRefresh] = useState(1)
  const [pcqSelectedRowKeys, setPcqSelectedRowKeys] = useState<any>();
  const [pctSelectedRowKeys, pctSetSelectedRowKeys] = useState<any>();
  const [pagination, setPagination] = useState<any>()

  const blCreationDrawer = (id) => {
    setDrawer(true)
    setActiveBlCreation(id)
    const req = new BargeBlCreationId(id, authContext.defaultPlant);
    service.getAllBlCreation(req).then(res => {
      if (res.status) {
        setActiveBlGrid(res.data)
      } else {
        setActiveBlGrid([])

      }
    }).catch(err => {

    })
  }

  const closeBlCreation = () => {
    setDrawer(false)
    getAllBarge()
    setDummyRefresh(prev => prev + 1);

  }

  const onReset = () => {
    blCreationFormRef.resetFields();
  }



  const blCreationSaveData = () => {
    blCreationFormRef.validateFields().then(async values => {
      let count = 1;
      for (const file of values?.billOfLading) {
        if (file?.filesData?.file) {
          const base64 = await getBase64(file?.filesData.fileList[0].originFileObj);
          const document = file.filesData.fileList[0]
          file.filesData = [{
            ...document,
            base64Url: base64,
            fileDescription: 'Invoice file' + " " + count,
            fileUploadId: file.fileUploadId
          }];
          count += 1;
        } else {
          file.filesData = [];
        }
      }
      service.blCreation({ ...values, barges: activeBlCreation, plantCode: authContext.defaultPlant, userName: authContext.user.userName }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          onReset()
          closeBlCreation()
          setDummyRefresh(prev => prev + 1);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })

    })
  }



  useEffect(() => {
    getAllBarge();
    getAllPurchaseContracts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getAllBarge = (offset?: number, limit?: number) => {
    const req = new PlantCodeReqDto(authContext.defaultPlant, limit, offset)
    service.getBargeGridData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
        if (!offset) {
          defaultPagination(res.data[0].noOfRecords);
        }
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getAllPurchaseContracts = () => {
    purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setPurchases(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  // const setIndex = (expanded, record) => {
  //   const expandedRows = []
  //   if (expanded) {
  //     expandedRows.push(record.pcId);
  //     setExpandedIndex(expandedRows);
  //   } else {
  //     setExpandedIndex(expandedRows);
  //   }
  // }

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`Barges-${currentDate}.xlsx`);
  };
  const redirectToFullView = (bargeId: number) => {
    navigate(pathToreDirect, { state: { bargeId } });
  }

  const redirectToView = (PurchaseContract: string) => {
    navigate(pathToView, { state: { contractId: PurchaseContract } });
  }

  const editClickHandler = (bargeId: number) => {
    navigate('/barge-update', { state: { bargeId: bargeId } });
  }

  const handleCancel = () => {
    setOpen(false);
    handleClose();
  };

  const handleSubmit = () => {
    const req = { id: selectedBargeId, pcQualities: pcQualitiesSelectedRowData?.qualityId, pcPurchaseType: pcQualitiesSelectedRowData.pcTypeId, pcId: contractData, plantCode: authContext.defaultPlant, userName: authContext.user.userName }
    service.updateBargeData(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTimeout(() => {
          getAllBarge();
          getAllPurchaseContracts();
          resetPcSelectModalData();
          setIndexValue(undefined);
          handleClose();
        }, 500);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
      console.log(err.message)
    })
  };

  const handleDropdownChange = (record, index) => {
    setGridData(prevData => {
      let newData: any = [...prevData]
      newData[index].isDropDown = true;
      return newData;
    });
    setIndexValue(index)
    setCounter(counter + 1);
  }


  const pcQualitiesRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPcqSelectedRowKeys(selectedRowKeys);
      setPcQualitiesSelectedRowData((prev) => {
        return {
          qualityId: selectedRows[0].qualityId
        }
      })
      setPcTypeData(selectedRows[0].purchaseTypeData);
    },
    selectedRowKeys: pcqSelectedRowKeys

  };


  const pcTypesRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      pctSetSelectedRowKeys(selectedRowKeys);
      setPcQualitiesSelectedRowData((prev) => {
        return {
          ...prev,
          pcTypeId: selectedRows[0].typeId
        }
      })
    },
    selectedRowKeys: pctSelectedRowKeys
  }

  const handleDropdownSelect = (value, record) => {
    setShowModal(true);
    setContractData(value);
    setSelectedBargeId(record.id);
    const req = new ContractIdRequest(value, authContext.defaultPlant);
    purchase.getQualityDetailsByPcId(req).then(res => {
      if (res.status) {
        setQualityData(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const resetPcSelectModalData = () => {
    setShowModal(false);
    setContractData(undefined);
    setSelectedBargeId(undefined);
  }

  const handleClose = () => {
    setShowModal(false);
    setPcQualitiesSelectedRowData(undefined);
    setPcqSelectedRowKeys(undefined);
    pctSetSelectedRowKeys(undefined);
    setPcTypeData(undefined);
  }


  const tableColumns: any = [

    {
      title: fm({ id: "mdm.components. bargeDetails.bargeId", defaultMessage: 'Barge Id' }),
      dataIndex: 'bargeId',
      key: 'bargeId',
      width: 130,
      align: 'left',
      fixed: 'left',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(Object.keys(record).map((key) => {
          if (key === 'bargeId') {
            return String(SequenceUtils.formatNumberToSpecificLength(record[key])).toLowerCase().includes(value.toLocaleString())
          } else {
            return String(record[key]).toLowerCase().includes(value.toLocaleString())
          }
        }))
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true))
          return true;
        else
          return false;
      },
      render: (text, record) => {
        const link = `/#/barge-detail-view?barge_id=${record.id}`;
        return <>
          <a href={link} className="link-primary">
            {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
          </a>
        </>
      }

    },
    {
      title: fm({ id: "mdm.components. bargeDetails.bargeNomination", defaultMessage: 'Barge Nomination' }),
      dataIndex: 'bargeNomination',
      key: 'bargeNomination',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.bargeNomination?.localeCompare(b.bargeNomination),
      sortDirections: ['descend', 'ascend'],


    },
    {
      title: fm({ id: "mdm.components. bargeDetails.jetty", defaultMessage: 'Jetty' }),
      dataIndex: 'loadingJetty',
      key: 'loadingJetty',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.loadingJetty?.localeCompare(b.loadingJetty),
      sortDirections: ['descend', 'ascend'],


    },
    {
      title: fm({ id: "mdm.components. bargeDetails.purchaseContract", defaultMessage: 'Purchase Contract' }),
      dataIndex: 'purchaseContract',
      width: 150,
      align: 'left',
      // sorter: (a, b) => a.purchaseContract?.localeCompare(b.purchaseContract),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const link = `/#/po-detail-view?pc_id=${record.purchaseContract}`
        return indexValue === index ?
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            onChange={(value) => handleDropdownSelect(value, record)}
            allowClear
            style={{ width: '122px' }}
            placeholder='Please Select'
          >
            {/* <Option>Please Select</Option> */}
            {purchases.map(item => {
              return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
            })}
          </Select> : <><a className="link-primary" href={link}>
            {record.purchaseContractNo}
          </a>&nbsp;&nbsp;&nbsp;
          {record?.eligibleBargeOperations &&
            <EditOutlined style={{ color: '#1890ff', fontSize: '15px' }} onClick={() => handleDropdownChange(record, index)} ></EditOutlined> }
          </>
      }
    },
    {
      title: fm({ id: "mdm.components. bargeDetails.businessNo", defaultMessage: 'Business No' }),
      dataIndex: 'businessNumber',
      key: 'businessNumber',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.businessNo?.localeCompare(b.businessNo),
      sortDirections: ['descend', 'ascend'],


    },
    {
      title: fm({ id: "mdm.components.bargeDetails.supplier", defaultMessage: 'Supplier' }),
      dataIndex: 'bargeVendor',
      key: 'bargeVendor',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.supplier?.localeCompare(b.supplier),
      sortDirections: ['descend', 'ascend'],


    },

    {
      title: fm({ id: "mdm.components.bargeDetails.mine", defaultMessage: 'Mine' }),
      dataIndex: 'mineName',
      key: 'mine',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.mine?.localeCompare(b.mine),
      sortDirections: ['descend', 'ascend'],

    },

    {
      title: fm({ id: "mdm.components. bargeDetails.purchaseQuality", defaultMessage: 'Purchase Quality' }),
      dataIndex: 'purchaseQuality',
      key: 'purchaseQuality',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.purchaseQuality?.localeCompare(b.purchaseQuality),
      sortDirections: ['descend', 'ascend'],


    },

    {
      title: fm({ id: "mdm.components. bargeDetails.bargeQuantity", defaultMessage: 'Barge Quantity' }),
      dataIndex: 'bargeQuantityInMt',
      key: 'bargeQuantityInMt',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.bargeQuantityInMt?.localeCompare(b.bargeQuantityInMt),
      sortDirections: ['descend', 'ascend'],


    },

    {
      title: fm({ id: "mdm.components. bargeDetails.returnCargoQuantity", defaultMessage: 'Return Cargo Quantity' }),
      dataIndex: 'returnCargoQuantityInMt',
      key: 'returnCargoQuantityInMt',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.returnCargoQuantityInMt?.localeCompare(b.returnCargoQuantityInMt),
      sortDirections: ['descend', 'ascend'],


    },

    // {
    //   title: fm({ id: "mdm.components. bargeDetails.redraftQuantity", defaultMessage: 'Redraft Quantity' }),
    //   dataIndex: 'redraftQuantity',
    //   key: 'redraftQuantity',
    //   width: 130,
    //   align: 'left',
    //   sorter: (a, b) => a.redraftQuantity?.localeCompare(b.redraftQuantity),
    //   sortDirections: ['descend', 'ascend'],


    // },

    {
      title: fm({ id: "mdm.components. bargeDetails.supplierSurveyor", defaultMessage: 'Supplier Surveyor' }),
      dataIndex: 'supplierSurveyorName',
      key: 'supplierSurveyor',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.supplierSurveyor?.localeCompare(b.supplierSurveyor),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: fm({ id: 'mdm.components.bargeDetails.status', defaultMessage: "Status" }),
      dataIndex: 'status',
      width: 130,
      align: 'left',
      render: (text, record) => <>
        <StatusTag status={record.status} />
      </>,

    },
    {
      title: "Action",
      align: 'left',
      fixed: 'right',
      children: [
        {
          title: '',
          dataIndex: 'action',
          fixed: 'right',
          width: 105,
          align: 'left',
          render: (text, rowData) => (
            <span>

              <EditOutlined type="edit"
                onClick={() => {
                  editClickHandler(rowData.id)
                }}
                style={{ color: '#1890ff', fontSize: '20px' }}
              />
              &nbsp;&nbsp;&nbsp;


              <Tooltip title='More'>
                <Dropdown overlay={menu(rowData)} trigger={['click']}>
                  <MoreOutlined style={{ backgroundColor: '#0083C91A', color: '#0295D4', fontSize: '12px', cursor: 'pointer', padding: '6px', borderRadius: '50%', }} />
                </Dropdown>
              </Tooltip>


            </span>

          )
        },
      ]
    }

  ]

  const pcQualitiesColumns: any = [
    {
      title: "Laycan",
      dataIndex: 'layCan',
      align: 'center',
      render: (layCan, row) => {
        return layCan ? `${moment(layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(layCan.split(',')[1]).format('DD MMM YYYY')}` : ''
      }
    },
    {
      title: "Quality",
      dataIndex: 'quality',
      align: 'center',

    },
    {
      title: 'Mine',
      dataIndex: 'mineNameDesc',
      align: 'center'
    },
    // {
    //   title: 'Purchase',
    //   dataIndex: 'purchasingTerm',
    //   align: 'center',
    //   render: (text, record) => <>{() => (record.typeId)}{record.purchaseType}</>
    // },
    // {
    //   title: 'Coal Price',
    //   dataIndex: 'pcAverageCoalIndexPrice',
    //   width: 125
    // },
    {
      title: 'Purchasing Term',
      dataIndex: 'purchasingTerm',
      width: 125
    },
    // {
    //   title: 'Payment',
    //   dataIndex: 'purchasingTerm',
    //   align: 'center'
    // },
    {
      title: "Quantity",
      dataIndex: 'quantity',
      align: 'center'
    },
    {
      title: "Remaining",
      dataIndex: 'remaining',
      align: 'center',
      render: () => { return "0" }
    }
  ]

  const purchaseTypeColumns: any = [
    {
      title: 'MINE NAME',
      dataIndex: 'bpName',
    },
    {
      title: '	PURCHASE TYPE',
      dataIndex: 'purchaseType',
    },
    {
      title: 'ANCHORAGE NAME',
      dataIndex: 'fasAnchorage',
    },
    {
      title: 'COAL PRICE',
      dataIndex: 'coalPricePMT',
    },
  ];

  const defaultPagination = (total: number) => {
    const pageSize = SequenceUtils.paginationPageSize();
    setPagination(prev => {
      return {
        ...prev,
        current: 1, pageSize, total,
        pageSizeOptions: getPageOptions(total)
      }
    })
  }




  const getPageOptions = (total: number) => {
    //how many records we need
    const pageSize = SequenceUtils.paginationPageSize();
    let nearHundredRoundOff = Math.round(total / pageSize) * pageSize
    const pageSizes = [];
    let size = pageSize;
    while (size <= nearHundredRoundOff) {
      pageSizes.push(`${size}`);
      size += pageSize;
    }
    return pageSizes;
  }


  const getBargesByPaginationOnchange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => {
    //current page is a our selection pagination number
    //pageSize is a how many Records you need in screen
    getAllBarge(((pagination.current - 1) * pagination.pageSize), pagination.pageSize);
    setPagination(prev => {
      return {
        ...prev,
        current: pagination.current, pageSize: pagination.pageSize, total: gridData[0]?.noOfRecords,
        pageSizeOptions: getPageOptions(gridData[0]?.noOfRecords)
      }
    })
  }

  const onCancelBarge = (bargeId: string) => {
    const payload = new BargeIdRequestDto(bargeId, authContext.defaultPlant);
    service.cancelBarge(payload).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllBarge();
        setDummyRefresh(prev => prev + 1);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const menu = (rowData: any) => (
    <Menu>

      <Menu.Item
        onClick={() => { blCreationDrawer(rowData.id); setDummyRefresh(prev => prev + 1); }}
      >
        <PlusCircleFilled style={{ width: '16px', height: '16px', marginRight: '8px', color: '#64748B' }} />
        <span style={{ fontWeight: '400', fontSize: '14px', color: '#64748B' }}>Create BL</span>
      </Menu.Item>
      {rowData.status !== ContractStatusEnum.CANCELLED &&

        <Menu.Item
          onClick={() => onCancelBarge(rowData?.id)}
        >
          <CloseCircleOutlined style={{ width: '16px', height: '16px', marginRight: '8px', color: '#64748B' }} />
          <span style={{ fontWeight: '400', fontSize: '14px', color: '#64748B' }}>Cancel</span>
        </Menu.Item>
      }
    </Menu>
  );


  return (<>
    <Card
      title={<span style={{ color: 'white' }}>Barge Details</span>}
      style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
    >
      <Row justify='space-between'>
        <Col span={12}>
          <Row>

          </Row>
        </Col>
        <Col>
          <Row>
            <Col><Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} /></Col>
            <Col>
              <Tooltip placement="topRight" title="Create">
                <Button
                  size='small'
                  type='primary'
                  onClick={() => navigate('/createBarge')}
                >
                  Create
                </Button>
              </Tooltip>
              <Tooltip placement="topRight" title="Export As Excel">
                <Button
                  disabled={gridData.length === 0}
                  size='small'
                  type="default"
                  className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                >Export</Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>



      <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>

        <TabPane
          key="1"
          tab={<span style={{ color: "#2196f3" }}>{'Without Business No'}</span>}
        >
          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            <TabPane
              key="1-1"
              tab={<span style={{ color: "#2196f3" }}>{'Active'}</span>}
            >
              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber === null && item.status === "Active")}
                scroll={{ x: 500, y: '90vh' }}
                size="small"
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
            <TabPane
              key="1-2"
              tab={<span style={{ color: "#2196f3" }}>{'Cancelled'}</span>}
            >
              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber === null && item.status === ContractStatusEnum.ACTIVE)}
                scroll={{ x: 500, y: '90vh' }}
                size="small"
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>

            <TabPane
              key="1-3"
              tab={<span style={{ color: "#2196f3" }}>{'All'}</span>}
            >

              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber === null)}
                scroll={{ x: 500, y: '90vh' }}
                size="small"
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane
          key="2"
          tab={<span style={{ color: "#2196f3" }}>{'With Business No'}</span>}
        >
          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            <TabPane

              key="2-1"
              tab={<span style={{ color: "#2196f3" }}>{'Active'}</span>}
            >

              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber && item.status === 'Active')}
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
            <TabPane

              key="2-2"
              tab={<span style={{ color: "#2196f3" }}>{'Cancelled'}</span>}
            >

              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber && item.status === 'Cancelled')}
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
            <TabPane

              key="2-3"
              tab={<span style={{ color: "#2196f3" }}>{'All'}</span>}
            >

              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.businessNumber)}
                bordered
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
              ></Table>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          key="3"
          tab={<span style={{ color: "#2196f3" }}>{'All'}</span>}
        >
          <Tabs key={defaultTab} type={'card'} tabPosition={'top'} defaultActiveKey={defaultTab}>
            <TabPane

              key="3-1"
              tab={<span style={{ color: "#2196f3" }}>{'Active'}</span>}
            >
              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.status === 'Active')}
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
            <TabPane

              key="3-2"
              tab={<span style={{ color: "#2196f3" }}>{'Cancelled'}</span>}
            >
              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData.filter((item) => item.status === 'Cancelled')}
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered

              ></Table>
            </TabPane>
            <TabPane

              key="3-3"
              tab={<span style={{ color: "#2196f3" }}>{'All'}</span>}
            >
              <Table
                className='contracts'
                key={counter}
                rowKey={record => record.pcId}
                columns={tableColumns}
                dataSource={gridData}
                pagination={{ ...pagination, size: 'small', position: ['topRight'], }}
                onChange={(...args) => getBargesByPaginationOnchange(...args)}
                bordered
              ></Table>
            </TabPane>
          </Tabs>
        </TabPane>
        {/* <TabPane
          key="4"
          tab={<span style={{ color: "#2196f3" }} >{"BL Creation"}</span>}>
          <BlCreation formRef={blCreationFormRef} initialValues={undefined} layoutType={'vertical'} onResets={onReset} blCreationSaveData={blCreationSaveData}></BlCreation>
        </TabPane> */}
      </Tabs>
      <Modal open={showModal} width={'50%'} onCancel={handleClose} closable
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>
        ]}>
        <Table
          columns={pcQualitiesColumns}
          dataSource={qualityData}
          pagination={false}
          rowSelection={{ ...pcQualitiesRowSelection, type: 'radio' }}
          rowKey={record => record.qualityId}
        >
        </Table>
        {pcTypeData?.length &&
          <Table
            columns={purchaseTypeColumns}
            dataSource={pcTypeData}
            pagination={false}
            rowSelection={{ ...pcTypesRowSelection, type: 'radio' }}
            rowKey={record => record.typeId}
          >
          </Table>
        }

      </Modal>
      <Drawer
        width={720}
        open={showDrawer}
        onClose={closeBlCreation}
        closable={true}
        footer={undefined}
        bodyStyle={{ padding: 0 }}
      >
        <BlCreation formRef={blCreationFormRef} initialValues={undefined} layoutType={'vertical'} onResets={onReset} blCreationSaveData={blCreationSaveData} gridData={activeBlGrid} setDummyRefresh={setDummyRefresh} key={dummyRefresh}
        />

      </Drawer>

    </Card>
  </>);



}
export default BargeView;