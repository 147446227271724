import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined, SaveTwoTone } from "@ant-design/icons"
import { ExpenseEntryIdReq, ReportsService } from "@exportx/shared-models-and-services"
import { Button, Card, Col, Input, Popconfirm, Row, Select, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { AlertMessages, isPermissionExist, useAuthState } from "../../../common"
import DatePicker from "../../../common/data-picker/date-picker"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-solid-svg-icons"
import { isPermissionExists } from "../../../common/iam-client-react/permission-checker/permission-checker"
import { useNavigate } from "react-router-dom"

export const LoadingPlans = () => {
    const [data,setData] = useState<any[]>([])
    const [editData, setEditData] = useState<any>()
    const navigate = useNavigate()
    const { authContext } = useAuthState();

    const service = new ReportsService()

    useEffect(()=>{
        if(!isPermissionExists([890,891,892])) {
            return navigate('/')
        }
        getLoadingPlansData()
    },[])

    const getLoadingPlansData = () => {
        service.getLoadingPlansData().then((res)=>{
            if(res.status){
                setData(res.data)
            }
        }).catch((error)=>{
            AlertMessages.getErrorMessage(error.message)
        })
    }

    const deleteEntry = (value) => {
        service.deleteEntry({id: value}).then((res) => {
            if (res.status) {
                getLoadingPlansData()
                AlertMessages.getSuccessMessage(res.internalMessage)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)

            }
        }).catch((err) => {
            AlertMessages.getErrorMessage('')
        })
    }

    const rowDataUpdateHAndler = (value, key, index) => {
        const newData = [...data];
        newData[index][key] = value;
        newData[index].isUpdated = true;
        setData(newData);
    };

    const handleSubmit = () => {
        const req = data.filter((item) => item.isNewRecord === true || item.isUpdated === true)
        // console.log('req',req)
        // if(!req.length) {
        //     return AlertMessages.getErrorMessage("Please Fill the fields")
        // }
        req?.map((item)=>{
            item.createdUser = authContext.user.userName
            item.updatedUser = authContext.user.userName
            item.plantCode = authContext.defaultPlant
        })
        service.createLoadingPlans(req).then((res)=> {
           if(res.status){
                getLoadingPlansData()
                setEditData(null)
                AlertMessages.getSuccessMessage(res.internalMessage)
           }
        }).catch(err =>{
            AlertMessages.getErrorMessage(err.message)
        })
    }


    const columns: ColumnsType<any>= [
        {
            title: 'Operation Team',
            hidden: !isPermissionExists([890]),
            children: [
                {
                    title: 'Supplier',
                    dataIndex: 'supplier',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.supplier ? record.supplier : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'supplier',index)}}
                                placeholder="Supplier"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Country Party',
                    dataIndex: 'countryParty',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.countryParty ? record.countryParty : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'countryParty',index)}}
                                placeholder="country party"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    },
                },
                {
                    title: 'Mother Vessel',
                    dataIndex: 'motherVessel',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.motherVessel ? record.motherVessel : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'motherVessel',index)}}
                                placeholder="mother vessel"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'BG Name',
                    dataIndex: 'BGname',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.BGname ? record.BGname : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'BGname',index)}}
                                placeholder="Barge name"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Type Of Barge',
                    dataIndex: 'typeOfBarge',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.typeOfBarge ? record.typeOfBarge : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'typeOfBarge',index)}}
                                placeholder="type of barge"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Jetty Name',
                    dataIndex: 'jettyName',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.jettyName ? record.jettyName : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'jettyName',index)}}
                                placeholder="Jetty name"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Qty',
                    dataIndex: 'loadingQuantity',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.loadingQuantity ? record.loadingQuantity : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'loadingQuantity',index)}}
                                placeholder="Quantity"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Expected Date of Loading',
                    dataIndex: 'exptDateOfLoading',
                    render: (text, record, index) => {
                        const formattedDate = moment(record.exptDateOfLoading).isValid()
                            ? moment(record.exptDateOfLoading).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={moment(record.exptDateOfLoading).isValid() ? moment(record.exptDateOfLoading) : ""}
                                onChange={(date) => {
                                    if (moment(date).isValid()) {
                                        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate, 'exptDateOfLoading', index);
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                },
            ]
        },
        {
            title: 'Procurement Team',
            hidden: !isPermissionExists([891]),
            children: [
                {
                    title: 'Contract No',
                    dataIndex: 'contractNo',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.contractNo ? record.contractNo : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'contractNo',index)}}
                                placeholder="contract no"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Terms of Payments',
                    dataIndex: 'termsOfPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.termsOfPayment ? record.termsOfPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'termsOfPayment',index)}}
                                placeholder="Terms of payments"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Type of Payment',
                    dataIndex: 'typeOfPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.typeOfPayment ? record.typeOfPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'typeOfPayment',index)}}
                                placeholder="type of payment"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Quantity',
                    dataIndex: 'procurementQuantity',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.procurementQuantity ? record.procurementQuantity : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'procurementQuantity',index)}}
                                placeholder="quantity"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.price ? record.price : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'price',index)}}
                                placeholder="price"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.amount ? record.amount : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'amount',index)}}
                                placeholder="amount"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'PPH',
                    dataIndex: 'PPH',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PPH ? record.PPH : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PPH',index)}}
                                placeholder="pph"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'PPN',
                    dataIndex: 'PPN',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PPN ? record.PPN : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PPN',index)}}
                                placeholder="ppn"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Advance',
                    dataIndex: 'advance',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.advance ? record.advance : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'advance',index)}}
                                placeholder="advance"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'PI Adjustment',
                    dataIndex: 'PIAdjustment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PIAdjustment ? record.PIAdjustment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PIAdjustment',index)}}
                                placeholder="PI Adjustment"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Net Amount',
                    dataIndex: 'netAmount',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.netAmount ? record.netAmount : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'netAmount',index)}}
                                placeholder="Net Amount"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                }
            ]
        },
        {
            title: 'Finance Team',
            hidden: !isPermissionExists([892]),
            children: [
                {
                    title: 'Final Payment',
                    dataIndex: 'finalPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.finalPayment ? record.finalPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'finalPayment',index)}}
                                placeholder="Final payment"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Payment Status',
                    dataIndex: 'paymentStatus',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.paymentStatus ? record.paymentStatus : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'paymentStatus',index)}}
                                placeholder="Payment Status"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Expected Date of Receipt',
                    dataIndex: 'exptDateOfReceipt',
                    render: (text, record, index) => {
                        const formattedDate = record.exptDateOfReceipt
                            ? moment(record.exptDateOfReceipt).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={moment(record.exptDateOfReceipt).isValid() ? moment(record.exptDateOfReceipt) : ""}
                                onChange={(e)=>{
                                    if (moment(e).isValid()) {
                                        const formattedDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate,'exptDateOfReceipt',index)
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                },
                {
                    title: 'Expected Date of Settlement',
                    dataIndex: 'exptDateOfSettlement',
                    render: (text, record, index) => {
                        const formattedDate = record.exptDateOfSettlement
                            ? moment(record.exptDateOfSettlement).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={moment(record.exptDateOfSettlement).isValid() ? moment(record.exptDateOfSettlement) : ""}
                                onChange={(e)=>{
                                    if (moment(e).isValid()) {
                                        const formattedDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate,'exptDateOfSettlement',index)
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                }
            ]
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (text, record, index) => {
                return editData === index ? (
                    <>
                    <Tooltip placement="top" title="Save">
                        <SaveTwoTone
                            onClick={handleSubmit}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip placement="top" title="Cancel">
                        <CloseCircleOutlined
                            onClick={() => handleCancel(index)}
                            style={{ color: 'red', fontSize: '18px' }}
                        />
                    </Tooltip>
                    </>
                ) : (
                    <>
                    {isPermissionExists([889]) && (
                    <Tooltip placement="top" title="Edit">
                        <EditOutlined
                            type="edit"
                            onClick={() => {const newData = [...data];
                                newData[index].isUpdated = true; setEditData(index);setData(newData)}}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        />
                    </Tooltip>)}
                    &nbsp;&nbsp;
                    {isPermissionExists([889]) && (
                        <Popconfirm
                            onConfirm={e => {
                                deleteEntry(record.id)
                            }}
                            title={'Are you sure to delete ?'}>
                            <Tooltip placement="top" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
                                <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
                            </Tooltip>
                        </Popconfirm>)}
                    </>
                )
            },
        },
    ]

    const addNewRow = () => {
        const hasNewRecord = data.some((row) => row.isNewRecord);
        if (!hasNewRecord) {
            const newRow = { isNewRecord: true };
            setData([ newRow, ...data]);
            setEditData(0);
        } else {
            AlertMessages.getWarningMessage("Can't Add New Row ")
        }
    };


    const handleCancel = (index) => {
        const newData = data.filter((row, i) => !(row.isNewRecord && i === index));
        setData(newData);
        setEditData(null);
        getLoadingPlansData()
    };

    return (
        <Card
            title={<span style={{ fontSize: '24px' }}>Loading Plans</span>}
        >
            <Table
                rowKey={(row) => row.id}
                className='contracts'
                columns={columns}
                dataSource={data}
                scroll={{ x: 'max-content' }} 
                size="large"
                bordered
            />
            {isPermissionExists([889]) && (<Button type="dashed" icon={<PlusOutlined />} onClick={addNewRow} style={{ marginBottom: '16px' }}>
                Add Row
            </Button>)}
            <br />      
        </Card>
    )
}