import { Card, Checkbox, Col, Form, Row } from "antd"
import { useEffect, useState } from "react"



export const Reports = ({ formRef, initialValues }) => {
    const [values, setValues] = useState({
        reports: []
    })

    useEffect(() => {
        if (initialValues) {
          const features = initialValues?.featureIds?.split(',')
          setValues((prev) => {
            return {
              ...prev,
              reports: features?.filter((cou) => 889 <= +cou && +cou <= 892)?.map(Number),
            };
          });
        }
      }, [initialValues])
    
      useEffect(() => {
        formRef.setFieldsValue(values)
      }, [formRef, values])

    const onChange = (e: any, name?: string, value?: number[]) => {
        if (e.target.checked) {
          setValues((prev) => ({ ...prev, [name]: value }))
        }
        else setValues((prev) => ({ ...prev, [name]: [] }));
      }
    return (
        <Form
            name="Reports"
            initialValues={values}
            layout='vertical'
            form={formRef}
        >
            <Card
                title= 'Operation/procurement & Finance Team'
                extra={<Checkbox
                    name="Reports"
                    indeterminate={values.reports.length > 0 && values.reports.length < 4}
                    checked={values.reports.length === 4}
                    onChange={(e) => onChange(e, 'reports', [889, 890, 891, 892])}
                  >
                    <b>Select All</b>
                  </Checkbox>}
            >
                <Form.Item name="reports" style={{ marginBottom: 0 }}>
                    <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, reports: value }))}>
                        <Row>
                        <Col span={6}>
                            <Checkbox value={889} >Add/Update/Delete</Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox value={890} >View Opertion Team</Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox value={891} >View Procurement Team</Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox value={892} >View Finance Team</Checkbox>
                        </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
       
            </Card>
        </Form>
    )
}