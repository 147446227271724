import { CostingWorkLogsEnum, ContractModesEnum, PerformaCoalCostingService, PerFormaReqIdDto } from "@exportx/shared-models-and-services";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../../common";
import moment from "moment";
import { getLocalFormat, SequenceUtils } from "@exportx/ui-utils";
import { Typography } from "antd";
import { Table } from "antd";
import { ApprovalTracking } from "./approval-tracking";

interface PerformaApprovalDetailsProps {
    costingId: string;
    contractType: ContractModesEnum;
    remainingAmount?: number
}


export const PerformaApprovalDetails = (props: PerformaApprovalDetailsProps) => {
    const { costingId, contractType } = props;
    const { authContext } = useAuthState();
    const performaCostingsService = new PerformaCoalCostingService();
    const [data, setData] = useState<any>();
    const [advTotal, setAdvTotal] = useState<number>(0);
    const [bargeDetails, setBargeDetails] = useState<any>([])
    const { Text } = Typography;

    useEffect(() => {
        getAllPerFormaData()
    }, [costingId])

    console.log('remainingAmount', props?.remainingAmount)

    const getAllPerFormaData = async () => {
        const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
        await performaCostingsService.getAllPerFormaData(req).then(res => {
            if (res.status) {
                console.log(res.data)
                const displayExchangeRate = res.data?.costingTransactions[0]?.exchangeRate
                const exchangeDate = res.data?.costingTransactions[0]?.exchangeDate
                const totalPayable = (+res?.data?.costingsTotal + (+res.data?.taxTotal1 + +res?.data?.taxTotal2) - +res?.data?.tdsTotal)
                setData({ ...res.data, totalPayable: totalPayable, exchangeRate: displayExchangeRate, exchangeDate: exchangeDate })
                const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
                const bargeDetails = res.data?.costingTransactions?.map((item) => ({
                    ...item, incoterm: res.data?.incoterm, advAdjustment: advTotal,
                    totalPayable: totalPayable,
                    layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
                    priceAfterExchg: Number(+item?.coalPricePerMt * item?.exchangeRate),
                    remaining: + res.data?.totalAmount

                }))
                setBargeDetails(bargeDetails)
                setAdvTotal(advTotal)
            } else {
                console.log(res.internalMessage)
            }
        }).catch(err => console.log(err.message))
    };

    const sharedOnCell = (_, index?: number) => {
        if (index == 0) return { rowSpan: bargeDetails.length }
        else return { rowSpan: 0 }
    };

    const columns = [
        {
            title: 'Barge ID',
            dataIndex: 'bargeNo',
            key: 'bargeNo',
            render: (value, record) => {
                if (record.bargeId) {
                    const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
                    return <a href={link} className="link-primary" target="_blank">
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
                    </a>

                }
                return <span>{value}</span>

            }
        },
        {
            title: 'Barge Nomination',
            dataIndex: 'bgName',
            key: 'bgName',
        },
        {
            title: 'Business No',
            dataIndex: 'businessNo',
            key: 'businessNo',
        },
        {
            title: 'Laycan (Quality)',
            dataIndex: 'layCan',
            key: 'layCan',
            onCell: sharedOnCell,
        },
        {
            title: 'Inco Term',
            dataIndex: 'incoterm',
            key: 'incoterm',
            onCell: sharedOnCell,
        },
        {
            title: 'Quantity',
            dataIndex: 'inWardQty',
            key: 'inWardQty',
            onCell: sharedOnCell,
        },
        {
            title: `${contractType === ContractModesEnum.FREIGHT_COSTING ? 'Freight' : 'Price'}  PMT`,
            dataIndex: 'coalPricePerMt',
            key: 'coalPricePerMt',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.coalPricePerMt, record?.baseCurrency)}</span>

        },
        {
            title: `${contractType === ContractModesEnum.FREIGHT_COSTING ? 'Freight' : ''}  Price`,
            dataIndex: 'priceAfterExchg',
            key: 'priceAfterExchg',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.priceAfterExchg, record?.quoteCurrency || record?.baseCurrency)}</span>
        },
        {
            title: 'Total Payable',
            dataIndex: 'totalPayable',
            key: 'totalPayable',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.totalPayable, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Advance Adjustments',
            dataIndex: 'advAdjustment',
            key: 'advAdjustment',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.advAdjustment, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.remaining, record?.quoteCurrency)}</span>

        },
    ];

    return (
        <>
            <Table dataSource={bargeDetails} columns={columns} pagination={false} style={{ width: '100%' }} className="inner-table" />
            <table className="table table-bordered table-sm mt-4">
                <tr>
                    <th colSpan={2}>Exchange Date</th>
                    <td>
                        {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}
                    </td>
                </tr>
                <tr>
                    <th colSpan={2}>Exchange Rate</th>
                    <td>
                        <Text>{getLocalFormat(data?.exchangeRate, data?.billingCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th colSpan={2}>Total</th>
                    <td>
                        <Text>{getLocalFormat(data?.costingsTotal, data?.billingCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th>TAX 1</th>
                    <td>
                        <Text>{`${data?.taxName1 || ''} - ${data?.taxPercentage1 || ''}`}</Text>
                    </td>
                    <td>{getLocalFormat(data?.taxTotal1, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th>TAX 2</th>
                    <td>
                        <Text>{`${data?.taxName2 || ''} - ${data?.taxPercentage2 || ''}`}</Text>
                    </td>
                    <td>
                        <Text>{getLocalFormat(data?.taxTotal2, data?.billingCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th>TDS:</th>
                    <td><Text>{`${data?.tdsName || ''} - ${data?.tdsPercentage || ''}`}</Text></td>
                    <td>{getLocalFormat(data?.tdsTotal, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th colSpan={2} > Total Amount</th>
                    <td>{getLocalFormat(data?.totalPayable, data?.billingCurrency)}</td>

                </tr>
                <tr>
                    <th colSpan={3} >
                        Advance Adjustments
                    </th>
                    <td></td>

                </tr>
                {data?.advanceTarnsactionsData?.map((adv) => (
                    <tr key={adv.id}>
                        <th colSpan={2} >
                            Adv deduction against contract : {adv.adjustedCostingNo}
                        </th>
                        <td>{getLocalFormat(adv.totalAdjustedAmount, data?.billingCurrency)}</td>

                    </tr>
                ))}
                <tr>
                    <th colSpan={2} >
                        Total Advance Adjustments
                    </th>
                    <td> {getLocalFormat(advTotal, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th colSpan={2} >
                        To be paid to Supplier
                    </th>
                    <td>
                        <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.totalAmount, data?.billingCurrency)}</span>
                    </td>
                </tr>

                {props?.remainingAmount > 0 &&
                    <tr>
                        <th colSpan={2} >
                            Remaiming Amount
                        </th>
                        <td>
                            <span style={{ color: 'red', fontWeight: 'bold' }}>{getLocalFormat(props?.remainingAmount, data?.billingCurrency)}</span>
                        </td>
                    </tr>
                }
            </table>

            <br />
            <ApprovalTracking history={data?.workLog} />

        </>
    )
}
