import { AdvanceTypeReqDto, BGCoalCostingService, CalculationFilterDataReq, CommercialCoalCostingService, ContractModesEnum, CostingTypesEnum, PerformaAdvanceDataDto, PerformaCoalCostingService, PerformaPCCostingsDto, PerformaPCCostingsTransactionsDto, PerFormaReqIdDto, PurchaseContractService, ReferenceFeatures, SalesContractService } from '@exportx/shared-models-and-services';
import { getBase64, getNumberFromLocalString } from '@exportx/ui-utils';
import { Button, Card, Col, Drawer, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import { CoalCostingFilterComponent, ICoalCostingFilterInitialValues, PriceCalculationForm, SavedCostingsGrid } from '../common';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { SalesFreightAdvanceAdjustments } from './sales-freight-advance-adjustment';
import moment from 'moment';
import FreightScPriceAdjustment from './freight-sc-price-adjustment';

interface perFormaIProps {
  contractType: ContractModesEnum
}

export const SalesFreightCosting = (props: perFormaIProps) => {
  const { authContext } = useAuthState();
  const [priceCalculationData, setPriceCalculationData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<ICoalCostingFilterInitialValues>(null);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [formRef] = Form.useForm();
  const { contractType } = props
  const [counter, setCounter] = useState<number>(0);
  const [updatedData, setUpdatedData] = useState<any>();
  const [calcUpdateData, setCalcUpdateDate] = useState<any[]>([])
  const bgCoalCostingService = new BGCoalCostingService();
  const purchase = new PurchaseContractService();
  const sales = new SalesContractService();
  const performaCostingsService = new PerformaCoalCostingService();
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.costingId;
  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  const [invoiceContracts, setInvoiceContracts] = useState<PerformaAdvanceDataDto[]>([]);
  const [performaAdvance, setPerformaAdvance] = useState<PerformaAdvanceDataDto[]>([]);
  const cmService = new CommercialCoalCostingService();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);



  console.log('selectedRows =>', selectedRows)
  useEffect(() => {
    editClickHandler(paramBargeId);
  }, [paramBargeId]);

  const editClickHandler = (performaId) => {
    getAllData(performaId);
    // setUpdatedData(null);
    // setUpdatedData(performaId);

  };
  let referenceFeatures: ReferenceFeatures = ReferenceFeatures.SALES_FREIGHT_COSTING;

  const getPerformaNonFOBBargeData = (req: CalculationFilterDataReq) => {
    purchase.getPerformaNonFOBBargeData(req).then((res: any) => {
      if (res.status) {
        setPriceCalculationData(res.data);
      } else {
        setPriceCalculationData([]);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
      setPriceCalculationData([]);
    })
  }

  const getPerformaFOBBargeData = (req: CalculationFilterDataReq) => {
    bgCoalCostingService.getPerformaFOBBargeData(req).then((res: any) => {
      if (res.status) {
        setPriceCalculationData(res.data);
      } else {
        setPriceCalculationData([]);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
      setPriceCalculationData([]);
    })
  };

  const getPerformaNonSalesFOBBargeData = (req: CalculationFilterDataReq) => {
    sales.getPerformaNonSalesFOBBargeData(req).then((res: any) => {
      if (res.status) {
        setPriceCalculationData(res.data);
      } else {
        setPriceCalculationData([]);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
      setPriceCalculationData([]);
    })
  }

  const costingsSave = (PriceAdjustmentInitialValues: any) => {
    formRef.validateFields().then(async values => {
      const formFilesData = formRef.getFieldValue('filesData');
      let filesData = [];
      if (formFilesData?.file) {
        const base64 = await getBase64(formFilesData.file.originFileObj);
        filesData = [{
          ...formFilesData.file,
          base64Url: base64,
          fileDescription: 'Invoice file'
        }];
      }
      const transactionsArray: PerformaPCCostingsTransactionsDto[] = []
      for (const item of selectedRowsData) {
        console.log('item ===>', item)
        const transactionsDto = {
          ...new PerformaPCCostingsTransactionsDto(item.quantityInMt
            , item?.freightPricePmt, item.baseCurrency, item.quoteCurrency, item.exchangeRate, item.exchangeDate, item?.freightTotal, item.bgUId
            , authContext.defaultPlant, authContext.user.userName), perFormaTransactionId: updatedData?.costingTransactions[0]?.perFormaTransactionId
        }
        transactionsArray.push(transactionsDto)
      }
      const dto: PerformaPCCostingsDto = new PerformaPCCostingsDto(undefined, values.costingDate, PriceAdjustmentInitialValues.costingsTotal, Number(PriceAdjustmentInitialValues.tax1Percentage), Number(PriceAdjustmentInitialValues.tax2Percentage), PriceAdjustmentInitialValues.tax1Total, PriceAdjustmentInitialValues.tax2Total, PriceAdjustmentInitialValues.tdsPercentage, PriceAdjustmentInitialValues.tdsTotal, PriceAdjustmentInitialValues.totalAmount, authContext.defaultPlantCurrency,
        // PriceAdjustmentInitialValues.remarks,
        formRef.getFieldValue('remarks'),
        undefined, undefined, undefined, authContext.user.userName, PriceAdjustmentInitialValues.coalSuppliers[0].coalSupplierId, PriceAdjustmentInitialValues.tax1Id, PriceAdjustmentInitialValues.tax2Id, PriceAdjustmentInitialValues.tdsId, authContext.defaultPlant, values.incoterm, transactionsArray, filesData);
      performaCostingsService.savePerFormaCostings({
        ...values, ...dto, ...filterData, ...filesData,
        totalAdjustedValue: getNumberFromLocalString(String(values?.totalAdjustedValue)),
        costingInvoiceTotal: getNumberFromLocalString(String(values?.costingInvoiceTotal)),
        remainingBalance: getNumberFromLocalString(String(values?.remainingBalance)),
        adjustmentTransactions: adjustedData,
        costingTransactions: transactionsArray, contractType: ContractModesEnum.FREIGHT_COSTING, plantCode: authContext.defaultPlant, userName: authContext.user.userName, costingId: updatedData?.costingId, costingType: referenceFeatures
      }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => console.log(err.message));
    })
  };



  const loadBargesOnClickHandler = (values: ICoalCostingFilterInitialValues) => {
    setFilterData(values);
    const req = new CalculationFilterDataReq(authContext.defaultPlant, values.businessNumber, values.contractId, values.qualityId, values.incoterm, values.purchaseType)
    if (values.incoterm === 'FOB BARGE') {
      getPerformaFOBBargeData(req);

    } else {
      if (contractType === ContractModesEnum.SALES) {
        getPerformaNonSalesFOBBargeData(req);
      } else {
        getPerformaNonFOBBargeData(req);
      }
    }
  }
  const getAllData = async (id: string) => {
    const req = new PerFormaReqIdDto(id, ContractModesEnum.FREIGHT_COSTING, authContext.defaultPlant);
    await performaCostingsService.getAllPerFormaData(req).then(res => {
      if (res.status) {
        setUpdatedData(res.data);
        const bargeNo = res.data?.costingTransactions[0]?.bargeNo;
        const bargeNomination = res.data?.costingTransactions[0]?.bgName;
        const displayExchangeRate = res.data?.costingTransactions[0]?.exchangeRate
        const exchangeDate = res.data?.costingTransactions[0]?.exchangeDate
        setCounter(counter + 1);
        setCalcUpdateDate(res.data?.costingTransactions ? res.data?.costingTransactions : []);
        setPriceCalculationData([])
        formRef.setFieldValue('tax1Id', res.data.taxId1);
        formRef.setFieldValue('tax2Id', res.data.taxId2);
        formRef.setFieldValue('tdsId', res.data.tdsId);

        formRef.setFieldValue('bgName', res.data.costingTransactions);
        if (bargeNo) {
          formRef.setFieldValue('bargeNo', bargeNo);
        }
        if (exchangeDate) {
          const formattedDate = moment(exchangeDate).format('YYYY-MM-DD');
          formRef.setFieldValue('exchangeDate', formattedDate);
        }

        if (displayExchangeRate) {
          formRef.setFieldValue('exchangeRate', displayExchangeRate);
        }
        if (bargeNomination) {
          formRef.setFieldValue(' bargeNomination', bargeNomination);
        }
        setAdjustedData(res.data.advanceTarnsactionsData);
        const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2)).toLocaleString()
        formRef.setFieldValue('totalAdjustedValue', advTotal)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => console.log(err.message))
  };


  const getInvoiceContracts = (req: AdvanceTypeReqDto, incoterm: string) => {
    console.log('req ===>', req, incoterm)
    if (req?.bargeId?.length) {
      cmService.getInvoiceContracts(req).then(res => {
        if (res.status) {
          setInvoiceContracts(res.data);
          if (incoterm === "FOB BARGE") {
            setPerformaAdvance(res.data);
          }
        } else {
          // AlertMessages.getErrorMessage(res.internalMessage)
          setInvoiceContracts([]);
          setPerformaAdvance([]);
        };
      }).catch(err => console.log(err.message));
    } else {
      formRef.setFieldValue('advanceType', undefined);
      formRef.setFieldValue('invoiceContractId', undefined);
      formRef.setFieldValue('advanceBalance', undefined);
      formRef.setFieldValue('advanceToAdjust', undefined);
    }

  }



  return (
    <>
      <Card
        key={contractType}
        className='default-card-class'
        title={`Freight Costings - ${contractType === ContractModesEnum.PURCHASE ? "Purchase" : "Sales"} Contract`}
        extra={<Button
          onClick={() => { setGridDrawer(true); setDummyRefresh(dummyRefresh + 1); }}
          className='panel_button'
        >View</Button>
        }
      >

        <Row>
          <Col span={24}>
            <CoalCostingFilterComponent
              formRef={formRef}
              loadBargesOnClickHandler={loadBargesOnClickHandler}
              isBusinessNumberMandatory={false}
              contractType={contractType}
              initialValues={updatedData}
              setBargeData={setPriceCalculationData}
              costingType={CostingTypesEnum.PERFORMA}
            />
            {priceCalculationData.length !== 0 &&
              <PriceCalculationForm
                formRef={formRef}
                incoterm={filterData?.incoterm}
                priceCalculationData={priceCalculationData}
                costingType={CostingTypesEnum.SALE_FREIGHT_COSTING}
                setPriceCalculationData={setPriceCalculationData}
                setSelectedRowsData={setSelectedRowsData}
                calcUpdateData={calcUpdateData}
                getInvoiceContracts={getInvoiceContracts}
                setSelectedRows={setSelectedRows}

              />}

          </Col>

        </Row>
        {selectedRowsData.length !== 0 &&
          <Row>

            <Col span={12}>
              <Card
                key={contractType}
                className='default-card-class'
                title={'Advance Adjustment'}
              >
                <SalesFreightAdvanceAdjustments
                  bargeSelectionRowKeys={[selectedRowsData[0]?.bgUId]}
                  formRef={formRef}
                  key={contractType}
                  vendorId={selectedRowsData[0]?.coalSupplierId}
                  contractId={selectedRowsData[0]?.contractId}
                  totalInvoice={Number(initialValues?.costingsTotal)}
                  setAdjustedData={setAdjustedData}
                  adjustedData={adjustedData}
                  setInvoiceContracts={setInvoiceContracts}
                  invoiceContracts={invoiceContracts}
                  contractType={contractType}


                />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                key={contractType}
                className='default-card-class'
                title={'Price Adjustment'}
              >
                <FreightScPriceAdjustment
                  key={counter}
                  contractType={contractType}
                  formRef={formRef}
                  selectedRowsData={selectedRowsData}
                  costingDate={formRef.getFieldValue('costingDate')}
                  costingsSave={costingsSave}
                  filterData={filterData}
                  updateData={updatedData}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                />

              </Card>

            </Col>

          </Row>
        }
      </Card>
      <Drawer
        open={gridDrawer}
        onClose={() => setGridDrawer(false)}
        key={dummyRefresh}
        width={window.innerWidth > 768 ? '70%' : '85%'}

      >
        <SavedCostingsGrid
          referenceFeatures={referenceFeatures}
          contractType={ContractModesEnum.FREIGHT_COSTING}
          setPerFormaId={editClickHandler}
          costingType={CostingTypesEnum.PERFORMA}
          setGridDrawer={setGridDrawer}
        />
      </Drawer>
    </>

  )
}

export default SalesFreightCosting